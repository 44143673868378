@font-face {
    font-family: 'Poppins';
    src: url('../../public/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../public/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../public/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  
  /* Apply the font only to Configurator */
  .configurator-container {
    font-family: 'Poppins', sans-serif;
  }
  